@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

.footer {
    position: relative;
    width: 100%;
    background: #3586ff;
    min-height: 100px;
    padding: 20px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 15rem;
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    color: white; /* Default text color */
}

@keyframes colorChange {
    0%, 100% {
        --color-primary: #08d5fe;
        --color-gradient-start: #d6eb77;
        --color-gradient-mid1: #21b4b4;
        --color-gradient-mid2: #bd649b;
        --color-gradient-end: #ff7a59;
    }
    25% {
        --color-primary: #ff7a59;
        --color-gradient-start: #bd649b;
        --color-gradient-mid1: #08d5fe;
        --color-gradient-mid2: #d6eb77;
        --color-gradient-end: #21b4b4;
    }
    50% {
        --color-primary: #21b4b4;
        --color-gradient-start: #08d5fe;
        --color-gradient-mid1: #ff7a59;
        --color-gradient-mid2: #bd649b;
        --color-gradient-end: #d6eb77;
    }
    75% {
        --color-primary: #bd649b;
        --color-gradient-start: #21b4b4;
        --color-gradient-mid1: #d6eb77;
        --color-gradient-mid2: #ff7a59;
        --color-gradient-end: #08d5fe;
    }
}

@keyframes backgroundMove {
    0%, 100% {
        background-position: 0% 0;
    }
    25% {
        background-position: 100% 0;
    }
    50% {
        background-position: 200% 0;
    }
    75% {
        background-position: 300% 0;
    }
}

footer a {
    color: var(--color-bg);
    text-decoration: none; 
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
    list-style: none; 
    padding: 0; 
}

.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer__socials a {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    text-decoration: none; /* Ensure icons are not underlined */
}

.footer__socials a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);
}

.wave {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background: url("https://i.ibb.co/wQZVxxk/wave.png") repeat-x;
    background-size: 1000px 100px;
}

.wave#wave1 {
    z-index: 1000;
    opacity: 1;
    bottom: 0;
    animation: animateWaves 4s linear infinite;
}

.wave#wave2 {
    z-index: 999;
    opacity: 0.5;
    bottom: 10px;
    animation: animate 4s linear infinite;
}

.wave#wave3 {
    z-index: 1000;
    opacity: 0.2;
    bottom: 15px;
    animation: animateWaves 3s linear infinite;
}

.wave#wave4 {
    z-index: 999;
    opacity: 0.7;
    bottom: 20px;
    animation: animate 3s linear infinite;
}

@keyframes animateWaves {
    0% {
        background-position-x: 1000px;
    }
    100% {
        background-position-x: 0px;
    }
}

@keyframes animate {
    0% {
        background-position-x: -1000px;
    }
    100% {
        background-position-x: 0px;
    }
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    footer {
        margin-right: -12%;
    }
} 

/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {
    .permalinks {
        flex-direction: column;
        gap: 1.5rem;
    }
    footer {
        margin-right: -12%;
    }

    .footer__socials {
        margin-bottom: 2.6rem;
    }
}
