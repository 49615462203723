header {
    height: 100vh;
    padding-top:7em;
    overflow: hidden;
}

.header__container {
    text-align: center;
    position: relative;
}

/*========================  CTA =================  */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}


/*========================  HEADER SOCIALS =================  */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 2.3rem;
    bottom: 0rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background-color: #4db5ff;
}


/*======================== ME =================  */
.me {
    background:linear-gradient( #4db5ff, transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}



/*======================== SCROLL DOWN =================  */
.scroll__down {
    position: absolute;
    right: 2%;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;

}


/*    ==========================  MEDIA QUERIES (LARGE DEVICES) ================= */
@media screen and (min-width:1025px) {
    header {
        height: 110vh;

    }
  .container.header__container {
    display: flex;
    flex-direction: column;
  }
  .me {
    margin-top: 13rem;
    align-items: center;
    width: 25%;
    height: 50vh;
  }
}


/*    ==========================  MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width:1024px) {
    header {
        margin-right: -12%;
        height: 120vh;
    }
    
}



/*    ==========================  MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width:600px) {
          header {
            height: 100vh;
          }

          .header__socials, .scroll__down {
            display: none;
          }
}