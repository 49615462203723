.container .contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background-color: var(--color-bg-varient);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-varient);

}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/*======================= FORM ===========================*/

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    /* background: transparent; */
    background-color: rgb(14, 8, 40);
    /* border: 2px solid var(--color-primary-varient); */
    border: .1px solid rgb(210, 232, 146);
    /* resize: none; */
    color: var(--color-white);
}




/*   ========================== MEDIA QUERIES (LARGE DEVICES) ================= */
@media screen and (min-width:1025px) {
    .container  {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4%;
    }
  
}

/*    ==========================  MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width:1024px) {
    .container .contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    .contact__options {
        margin-left: 10%;
        margin-right: -10%;
    }
    form {
        margin-top: 15%;
        margin-left: 10%;
    }
}



/*    ==========================  MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width:600px) {
    .container .contact__container {
        width: var(--container-width-sm);
    }
}