.container .testimonials__container {
    width: 40%;
    padding-bottom: 4rem;

}

.client__avatar {
    width: 7rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-varient);
}

#testimonials {
    margin-top: 20rem;
}

.testimonial {
    background: var(--color-bg-varient);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.client__review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
}
.swiper-pagination-clickable .swiper-pagination-bullet{
     background: var(--color-primary);
}


/*    ==========================  MEDIA QUERIES (LARGE DEVICES) ================= */
@media screen and (min-width:1025px) {
   section {
    padding-top: 10rem;
   }
   #testimonials {
    margin-top: 25rem;
   }
} 
/*    ==========================  MEDIA QUERIES (MEDIUM DEVICES) ================= */
 @media screen and (max-width:1024px) {
    .container .testimonials__container {
        width: 60%;
    }
} 



/*    ==========================  MEDIA QUERIES (SMALL DEVICES) ================= */
 @media screen and (max-width:600px) {
  .container .testimonials__container {
   width: var(--container-width-sm); 
      
  }
  .client__review {
    width: var(--container-width-sm);
  }

}  
